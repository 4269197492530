<template>
  <ProgressBar
    v-show="isRequesting"
    mode="indeterminate"
    style="height: 0.4em"
  />
</template>

<script>
import { computed } from "@vue/reactivity";
import ProgressBar from "primevue/progressbar";
import { useStore } from "vuex";

import { GET_LOADING_STATUS } from "../../store/modules/global/global.getters.type";

export default {
  name: "ProgressBarCustom",
  components: { ProgressBar },
  setup() {
    const store = useStore();
    const isRequesting = computed(() => store.getters[GET_LOADING_STATUS]);

    return {
      isRequesting,
    };
  },
};
</script>

<style scoped></style>
