<template>
  <div class="layout-footer">
    <div class="p-grid">
      <div class="p-col">
        <a
          class="p-d-flex p-jc-end p-mt-2"
          :href="url.condition"
          target="_blank"
          >{{ $t("footer.condition") }}</a
        >
      </div>

      <div class="p-col">
        <a
          class="p-d-flex p-jc-center p-mt-2"
          :href="url.private"
          target="_blank"
          >{{ $t("footer.privacy") }}</a
        >
      </div>
      <div class="p-col">
        <Dropdown
          v-model="selectedLocale"
          class="language"
          data-testid="language"
          aria-label="language"
          option-label="language"
          option-value="code"
          :options="availableLocales"
          @change="onChangeLocale"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { useI18n } from "vue-i18n";

import { useLocaleSwitcher } from "../common/useLocaleSwitcher";

export default {
  name: "TheFooter",
  setup() {
    const { t } = useI18n();
    const { selectedLocale, availableLocales, onChange } = useLocaleSwitcher();
    const url = {
      condition: t("footer.condition_link"),
      private: t("footer.privacy_link"),
    };

    const onChangeLocale = () => onChange(selectedLocale.value);

    return {
      url,
      availableLocales,
      selectedLocale,
      onChangeLocale,
    };
  },
};
</script>

<style scoped>
.layout-footer {
  background-color: transparent;
  padding: 1em 2em;
}
a {
  color: #002664;
  font-weight: bold;
}
.language {
  -webkit-appearance: button;
  -webkit-border-radius: 2px;
  -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  -webkit-padding-end: 20px;
  -webkit-padding-start: 2px;
  -webkit-user-select: none;
  background-position: center right;
  background-repeat: no-repeat;
  border: 1px solid #aaa;
  color: #002664;
  font-size: inherit;
  margin: 0;
  overflow: hidden;
  padding-top: 2px;
  padding-bottom: 2px;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
