<template>
  <div class="layout-wrapper">
    <progress-bar-custom />
    <div class="layout-main" />
    <div class="title">
      <div class="p-grid p-flex-column">
        <div class="p-col p-as-center p-mb-2">
          <img
            src="/assets/layout/images/logo.png"
            alt="Herta Security logo"
            width="300"
          />
        </div>
        <div class="p-col p-as-center p-mb-4">
          <h2>{{ title }}</h2>
        </div>
      </div>
    </div>
    <div class="centered">
      <div class="form-data">
        <slot />
      </div>
    </div>
    <the-footer />
  </div>
</template>

<script>
import ProgressBarCustom from "../common/ProgressBarCustom";
import TheFooter from "./TheFooter";

export default {
  name: "TheLayout",
  components: {
    ProgressBarCustom,
    TheFooter,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
  },
};
</script>

<style scoped>
.title {
  position: fixed;
  top: 30%;
  left: 50%;
  /* bring your own prefixes */
  transform: translate(-50%, -50%);
}
.centered {
  position: fixed;
  top: 55%;
  left: 50%;
  /* bring your own prefixes */
  transform: translate(-50%, -50%);
}
.form-data {
  width: 420px;
}
</style>
